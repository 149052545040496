:root {
  --icon-size: 4rem;
  --icons-per-row: 6;
}

.Icon svg {
  width: 100%;
  height: 100%;
}

.IconContainer {
  width: var(--icon-size);
  height: var(--icon-size);
  display: flex;
  justify-content: center;
  align-items: center;
}

.IconContainer > Button {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.IconPickerPopper {
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;
  width: calc(var(--icon-size) * var(--icons-per-row));
}

.IconPicker {
  cursor: pointer;
}
