.EditCard {
  display: flex;
  gap: 1rem;
}

.Content {
  flex-grow: 1;
}

.Buttons {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.Divider {
  margin: 1rem 0 1rem 0 !important;
}

.IconPicker {
  width: 75%;
  height: 75%;
}

.NoInteraction {
  background-color: #e8e8e8;
  border-radius: 0.1rem;
  margin-top: 0.5rem;
  outline: 0.5rem solid #e8e8e8;
}

.REMOVED {
  background-color: rgba(200, 99, 189, 0.75);
}

.ADDED {
  background-color: rgba(99, 200, 110, 0.75);
}

.TOUCHED {
  background-color: rgba(99, 139, 200, 0.75);
}

.Action {
  background-color: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.newBox {
  position: absolute;
  background-color: #c8a063;
  color: white;
  padding: 5px 10px;
  top: 0;
  right: 0;
}

.newBox > p {
  margin: 0;
}
