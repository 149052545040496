.TripleElementCard {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.LeftElement {
  width: 128px;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RightElements {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
}
