.Card {
  margin: 16px 0;
  padding: 16px 0;
}

.CardHeader {
  padding: 0 16px;
}

.CardContent {
  padding: 0 16px 16px;
}
