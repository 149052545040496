.Stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c8a063 !important;
  overflow: hidden;
}

.Stack * {
  color: white;
  max-width: 800px;
}

.StackHeader {
  width: 100%;
  padding: 0.5rem 0 0.5rem 0;
  background-color: #aa7942;
  text-align: center;
  opacity: 0.75;
}

.StackEntry {
  width: 100%;
  text-align: center;
}

.StackEntry:not(:last-child) {
  border-bottom: solid 1px #aa7942;
}
