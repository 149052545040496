.RegularText,
.BoldText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.RegularText {
  composes: MuiTypography-root from global;
  font-weight: 200 !important;
  opacity: 0.85;
}

.BoldText {
  composes: MuiTypography-root from global;
  font-weight: 600 !important;
}
