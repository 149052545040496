.save-button {
  position: fixed;
  bottom: 0;
  left: 200px;
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  z-index: 1;
}
