.BookingId {
  composes: MuiTypography-root from global;
  font-weight: 200 !important;
  opacity: 0.85;
}

.ProductName {
  composes: MuiTypography-root from global;
  font-weight: 600 !important;
}
