.SelectList {
  margin-top: 12px;
}

.Header {
  display: flex;
  align-items: center;
}

.HeaderTitle {
  flex-grow: 1;
}

.SelectRowContainer {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.SelectRowContainer:not(:last-child) {
  margin-bottom: 12px;
}
